<template>
  <section v-if="tag">  
    <span v-if="selected === 'article'">
      <span v-for="(child, index) in tag.children"  :key="index">
        <h4 v-if="child.tagName ==='title' && child.children.length > 0">
          {{ child.children[0].value }}
        </h4> 
        <span v-else-if="child.type ==='text'">
          {{ child.value }} 
        </span>
        <tag-dispatcher
          v-else
          :tag="child"
          :parent="tag.tagName"
          :selected="selected"
          :images="images"
        />
      </span>
    </span>
  </section>
</template>
 
<script> 

import {
} from 'bootstrap-vue' 

export default {

  components: { 
    //components that may be recursive can be done this way
    //https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
    TagDispatcher: () => import('./TagDispatcher.vue'),
    
  }, 
  props: {
    tag: {
      type: Object,
      default: null
    },
    images: {
      type: Object,
      default: null
    },
    selected: {
      type: String,
      default: null
    },
  },
  data () {
    return {
    }
  }, 
  mounted () { 
    //console.log('SecTag mounted', this.tag)
  },
  methods: { 
  },
}
</script>
